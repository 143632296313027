<!--
 * @Author: lsw lsw_0524@163.com
 * @Date: 2024-01-11 19:57:18
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2024-01-26 10:22:57
 * @FilePath: \yiyun_project\src\views\extension\distribution\accelerateList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="手机号：">
        <el-input size="small" v-model="formData.mobile"></el-input>
      </el-form-item>
      <el-form-item label="用户名称:">
        <el-input size="small" v-model="formData.distribution_name"></el-input>
      </el-form-item>
      <el-form-item label="到期时间：">
        <el-date-picker v-model="formData.create_time" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" type="primary" @click="getDataList(1)">搜索</el-button>
        <el-button size="small" type="text" @click="clearSearch">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="dataInfo.list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="id" label="ID" align="center"></el-table-column>
      <el-table-column prop="distribution_info.nickname" label="用户名称" align="center"></el-table-column>
      <el-table-column prop="distribution_info.mobile" label="手机号" align="center"></el-table-column>
      <el-table-column prop="money" label="押金" align="center"></el-table-column>
      <el-table-column label="押金到期时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.expires_time) }}</template>
      </el-table-column>
      <el-table-column label="创建时间" align="center">
        <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
      </el-table-column>
    </el-table>
    <Paging :total="dataInfo.total_number" :page="dataInfo.page" :pageNum="dataInfo.rows" @updatePageNum="updateData"></Paging>
  </el-main>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      getDateformat,
      formData: {
        mobile: '',
        distribution_name: '',
        create_time: [],
      },
      dataInfo: {
        page: 1,
        rows: 10,
        list: [],
        total_number: 0,
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    clearSearch() {
      this.formData = {
        mobile: '',
        distribution_name: '',
        create_time: [],
      };
      this.getDataList(1);
    },
    updateData(val, status) {
      if (status == 0) {
        this.dataInfo.rows = val;
      } else {
        this.dataInfo.page = val;
      }
      this.getDataList();
    },
    getDataList(style) {
      if (style == 1) {
        this.dataInfo.page = 1;
      }
      let obj = {
        page: this.dataInfo.page,
        rows: this.dataInfo.rows,
      };
      if (this.formData.mobile) obj.mobile = this.formData.mobile;
      if (this.formData.distribution_name) obj.distribution_name = this.formData.distribution_name;
      if (this.formData.create_time?.length) {
        obj.expires_time = [this.formData.create_time[0].getTime() / 1000, this.formData.create_time[1].getTime() / 1000];
        if (obj.expires_time[0] == obj.expires_time[1]) obj.expires_time[1] = obj.expires_time[0] + 60 * 60 * 24 - 1;
      }
      this.$axios.post(this.$api.storageList.stockList, obj).then(res => {
        if (res.code == 0) {
          this.dataInfo.list = res.result.list;
          this.dataInfo.total_number = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
</style>
